import { ReactNode } from "react";
import {Box} from "@material-ui/core";
import { Header } from "./Header";

type Props ={
    children: ReactNode
}
export const Layout = ({children}: Props) => {
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Header />
                {children}
            </Box>
        </>
    )
}