import React from "react";
import {Typography, Accordion, AccordionSummary, AccordionDetails, Grid} from "@material-ui/core";
import { Layout } from "../comp/Layout";
import { useSize } from "../../hooks/useSize";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

// スタイルを定義
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15)
    },
  }),
);

export const Support = () => {

    const {isMobileSize} = useSize()
    if(isMobileSize) {

        return <SupportMobile />
    } else {

        return <SupportPc />

    }
}


const SupportMobile = () => {
    return (
        <>
            <Layout>
                <Title />
                <HiramekiBody />
                <SupportBody />
                <AccountBody />
                <OtherBody />
            </Layout>
        </>
    )
}


const SupportPc = () => {
    return (
        <>
            <Layout>
                <Title />
                <HiramekiBody />
                <SupportBody />
                <AccountBody />
                <OtherBody />
            </Layout>
        </>
    )
}

const Title = () => {
    return (
        <>
            <Typography
                variant="h1"
                color="inherit"
                align={"center"}
            >
                サポート
            </Typography>
            <Typography
                variant="h2"
                color="inherit"
                align={"center"}
            >
                各機能の使い方
            </Typography>
        </>
    )
}

const HiramekiBody = () => {
    const classes = useStyles();
    return (
        <>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>閃(ひらめき)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={8}>
                        <Grid item sm={12}>
                            <Typography>
                                なんでもない些細な時に、ビビビッとアイディアが思い浮かぶことがありますよね。
                                日本語ではこれを「閃き(ひらめき)」と言います。一瞬の閃光のように考えが脳裏をかすめるような感覚です。
                            </Typography>
                            <Typography>
                                D-Aicではこの一瞬の閃きをメモできる機能を追加しました！
                            </Typography>
                        <Grid item sm={12}>
                            <img src={`${process.env.PUBLIC_URL}/assets/idea_create_button.jpeg`} alt="Logo" />
                        </Grid>
                        <Grid item sm={12}>
                            <img src={`${process.env.PUBLIC_URL}/assets/idea_create_page.jpeg`} alt="Logo" />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                1.閃いたらボタンを押してアイディアを記載して保存しよう
                            </Typography>
                            <Typography>
                                2.に入力して　3.で保存
                            </Typography>
                        </Grid>

                        <Grid item sm={12}>
                            <img src={`${process.env.PUBLIC_URL}/assets/idea_list.jpeg`} alt="Logo" />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                保存したデータは「アカウント」タブの「閃」から確認できます。
                            </Typography>
                        </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

const SupportBody = () => {
    const classes = useStyles();

    return (
        <>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>目標の作成</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={8}>
                        <Grid item sm={12}>
                            <img src={`${process.env.PUBLIC_URL}/assets/create_goal_menu_list_open.jpeg`} alt="Logo" />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                1.「目標の作成」ボタンを押す。目標作成リスト(2.)が表示される。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <img src={`${process.env.PUBLIC_URL}/assets/create_goal_menu_list.jpeg`} alt="Logo" />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                2. 「巻の作成」から巻を作成できます。巻とは目標を一纏めにできる機能です。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                例：　巻「D-Aicアプリプロジェクト」→目標１「アプリリリース」→目標２「アプリのメンテナンス（みんなが使いやすいように）」→目標３「アプリを日本中に広める」
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                3. 「目標の作成」から目標を作成できます。目標とは現在地点から自分が目指す地点までの道筋です。
                                ここで自分がどこを目指しているのか、しっかりと考えてみよう。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <img src={`${process.env.PUBLIC_URL}/assets/create_maki.jpeg`} alt="Logo" />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                4. 巻の作成画面です。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                巻のタイトルは自由に記載できますが、本のタイトルのようにどういった目標を一纏めにしているか分かるようにすると良いでしょう。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                巻のキーは半角英数字で記載できます。巻の別名となっているので、わかりやすい記載がおすすめです。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                巻の説明を記載できます。どういった目的で一纏めにしているか等、薪に関する説明を入力しましょう。
                            </Typography>
                        </Grid>

                        <Grid item sm={12}>
                            <img src={`${process.env.PUBLIC_URL}/assets/create_goal.jpeg`} alt="Logo" />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                5. 目標の作成画面です。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                目標のタイトルは自由に記載できます。詳細に記載するより簡潔に記載するよ良いでしょう。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                目的は自由に記載できます。目標を立てた目的を記載しましょう。
                                時間が経つにつれ、なぜ目標を立てたのか曖昧になってしまい道がずれることもありますので、しっかり記載しておきましょう。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                どのように目標を達成するのか、方法を自由に記載できます。必要な技術、手法など細かく記載しましょう。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                いつまでに目標を達成したいのか、完了予定日を入力できます。まずはざっくり入力しましょう。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <img src={`${process.env.PUBLIC_URL}/assets/account_main_goal.jpeg`} alt="Logo" />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                6. 「4.」で作成した巻を一覧で表示します。巻きの一覧なので「書」タブでまとめています。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                7. 「5.」で作成した目標を一覧で表示します。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                8. 達成した目標を一覧で表示しています。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                9. ブックマークした目標を一覧で表示します。
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

const AccountBody = () => {
    const classes = useStyles();
    return (
        <>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>アカウント</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={8}>
                        <Grid item sm={12}>
                            <img src={`${process.env.PUBLIC_URL}/assets/account_main.jpeg`} alt="Logo" />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                1.アカウトのバックイメージの設定ができます。写真ボタンを押すと、フォトライブラリまたは写真を撮って張り替えが可能です。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                2.アカウントのアイコンの設定ができます。写真ボタンを押すと、フォトライブラリまたは写真を撮って張り替えが可能です。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                3.アカウントの氏名が表示されます。
                                「Googleログイン」でログインした場合Googleから連携された氏名を使用します。
                                「Apple IDログイン」でログインした場合Appleから連携された氏名を使用します。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                4.アカウントのニックネームが表示されます。
                                初回ログイン時は未登録なので「5.」編集から更新できます。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                5.アカウント情報編集ができます。
                                氏名やニックネームの更新ができます。またアカウント削除も可能です。
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>
                                6.D-Aicからログアウトできます。
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

const OtherBody = () => {
    const classes = useStyles();
    return (
        <>
        <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>その他問い合わせ</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={8}>
                        <Grid item sm={12}>
                            <Typography>
                                メールアドレス(emai-address): goen.aws.manage@gmail.com
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}