import React from "react"
import {db} from "../../config/FirebaseConfig"

import { doc, getDocs, addDoc, collection, deleteDoc, getDoc, DocumentReference, DocumentSnapshot, DocumentData } from 'firebase/firestore';
import { PortalHomeDataEntity } from "../entity/PortalHomeData";

export const portalHomeRepository = () => {
    const getHomeData = async(): Promise<PortalHomeDataEntity> => {
        let docRef: DocumentReference<DocumentData> = doc(db, "home", "home_1")
        const snap = await getDoc(docRef)
        return {accountCount: snap.get("account_count") as number,
                goalCount: snap.get("goal_count") as number,
                goalArchiveCount: snap.get("goal_archive_count")}
    }

    return {getHomeData}
}