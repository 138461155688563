import { FC, useEffect, useRef, useState } from "react";
// import {useMediaQueryContext} from "../../context/MediaContext";
import {Card, Container, Typography, Grid, makeStyles, createStyles, Theme} from "@material-ui/core";
import { portalHomeRepository } from "../../domain/repository/PortalHomeRepository";
import { PortalHomeDataEntity } from "../../domain/entity/PortalHomeData";


const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        content: {
            padding: 10,
            flexGrow: 1,
            overflow: "auto",
        },
        cardStyle: {
            marginBlock: 10,
            padding: 10,
        }
    })
)

export const UnAuth = () => {
    // const {isMobile, isTablet, isPc} = useMediaQueryContext()
    const { getHomeData } = portalHomeRepository()
    const refFirstRef = useRef(true);

    const [homeData, setHomeData] = useState<PortalHomeDataEntity>(
        {accountCount: 0,
         goalCount: 0,
         goalArchiveCount: 0});

    useEffect(() => {

        if (process.env.NODE_ENV === "development") {
            if (refFirstRef.current) {
            refFirstRef.current = false;
            return;
            }
        }
        (async () => {
            var res: PortalHomeDataEntity = await getHomeData()
            setHomeData(res)
        })()
    }, [])

    return (
        <Main homeData={homeData}/>
    )

}

const Main: FC<{homeData: PortalHomeDataEntity}> = ({homeData}) => {
    const classes = useStyles();
    return (
        <Container>
            <Grid container spacing={5} alignItems="center" justifyContent="center">
                <Grid item xs={10}  md={8}  className={classes.cardStyle}>
                    <Card>
                        <DAicDataDisp homeData={homeData} />
                    </Card>
                </Grid>
                <Grid item xs={10} md={8}  >
                    <MainTitle />
                    <MainBody />
                </Grid>
                <Grid item xs={10} md={8}  >
                    <AppDescTitle />
                </Grid>
                <Grid item xs={10} md={8}  >
                    <AppDescBody />
                </Grid>
                <Grid item xs={10} md={8}  >
                    <Card>
                        <GoalImg />
                        <GoalBody />
                    </Card>
                </Grid>
                <Grid item xs={10} md={8}  >
                    <Card>
                        <ProcessImg />
                        <ProcessBody />
                    </Card>
                </Grid>
                <Grid item xs={10} md={8}  >
                    <Card>
                        <TaskImg />
                        <TaskBody />
                    </Card>
                </Grid>
                <Grid item xs={10} md={8}  >
                    <Card>
                        <ResolveImg />
                        <ResolveBody />
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

const DAicDataDisp: FC<{homeData: PortalHomeDataEntity}> = ({homeData}) => {
    const classes = useStyles();
    return (
        <Container  className={classes.content}>
            <Typography>
                現在のアカウント数：{homeData.accountCount}人
            </Typography>
            <Typography>
                本日作成された目標数：{homeData.goalCount}
            </Typography>
            <Typography>
                本日達成された目標数：{homeData.goalArchiveCount}
            </Typography>
        </Container>
    )
}

const MainTitle = () => {
    return (
        <Typography
            variant="h3"
            color="inherit"
        >
            自分が立てた目標を達成しよう
        </Typography>
    )
}

const MainBody = () => {

    return (
        <>
            <ul>
                <li>最終的にどういうふうになりたいかのイメージ</li>
                <li>そのイメージを実現するための道筋</li>
                <li>課題の解決</li>
            </ul>
            これらを１つの目標として作成<br />
            また整理できるようにし<br />あなたの目標達成のフォローをします。

        </>
    )
}
const AppDescTitle = () => {
    return (
        <Typography
            variant="h3"
            color="inherit"
        >
            「D-Aic」とは何か
        </Typography>
    )
}
const AppDescBody = () => {
    return (
        <Grid container spacing={2}>
            <Grid item>
                日本語の「大工」から誕生しました。より世界に広めたいと思い、英語訛りな言葉に変換しています。
            </Grid>

            <Grid item>
                <Typography
                    variant="h4"
                    color="inherit"
                >
                    なぜ「大工」なのか
                </Typography>
            </Grid>
            <Grid item>
                大工とは木造建築物を組み立てる、日本を代表する技術集団です。彼らの「物を作る」力は長い年月をかけて、
                過去から現在まで継承されています。
            </Grid>
            <Grid item>
                彼らのようにITを通して「物を作る」方法、楽しさを皆んなと伝えたいと思います。
            </Grid>
            <Grid item>
                彼らに敬意を払い、これからも「物を作る」を伝えていきます。
            </Grid>
        </Grid>
    )
}

const GoalImg = () => {
    return (
        <img src={`${process.env.PUBLIC_URL}/assets/goal_mobile.png`} alt="Logo" />
    )
}

const ProcessImg = () => {
    return (
        <img src={`${process.env.PUBLIC_URL}/assets/process_mobile.png`} alt="Logo" />
    )
}

const TaskImg = () => {
    return (
        <img src={`${process.env.PUBLIC_URL}/assets/task_mobile.png`} alt="Logo" />
    )
}

const ResolveImg = () => {
    return (
        <img src={`${process.env.PUBLIC_URL}/assets/resolve_mobile.png`} alt="Logo" />
    )
}

const GoalBody = () => {
    return (
        <Typography
            variant="body1"
            color="inherit"
        >
            まずは目標を決めること<br />
            ざっくりで良いので自分がイメージした目標を作成します。
            細かいことは気にせず、ざっくりとした記載で大丈夫です。ただし、決めたことは達成するまでなるべく変えないことをお勧めします。
            また、いつまで達成するか期日を決めると良いです。
        </Typography>

    )
}

const ProcessBody = () => {
    return (
        <Typography
            variant="body1"
            color="inherit"
        >
            決めた目標を達成するまでの工程の作成<br />
            目標達成にたどり着くまでの道筋となります。
            この道筋を最初に作成することで、最速で目標達成することができます。
        </Typography>

    )
}

const TaskBody = () => {
    return (
        <Typography
            variant="body1"
            color="inherit"
        >
            問題発生<br />
            実際に作業すると問題が発生することもあります。
            発生した問題はしっかりと記載し問題解決に備えましょう。
        </Typography>

    )
}

const ResolveBody = () => {
    return (
        <Typography
            variant="body1"
            color="inherit"
        >
            問題解決力<br />
            しっかりと問題を解決できるようにしましょう。

        </Typography>

    )
}