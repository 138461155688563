import { Layout } from "../comp/Layout";
import { UnAuth } from "./UnAuth";

export const Portal = () => {
    
    return <PortalMobile />
}

const PortalMobile = () => {
    return (
        <>
            <Layout>
                <UnAuth />
            </Layout>
        </>
    )
}